<template>
  <div v-if="currentUser.attributes" ref="el" class="mr-[5px]">
    <div @click="toggleProfile">
      <span class="flex items-center justify-center rounded-sm">
        <ProfilePhoto
          :url="currentUser.attributes.avatar"
          class="border-2 border-etBlue-80 cursor-pointer"
        />
      </span>
    </div>
    <ProfileMenuBody
      :expanded="expanded"
      :show="showProfile"
      :show-accounts="showAccounts"
      data-cy="profile_menu_body"
      @logout="handleLogout"
      @setShowAccounts="setShowAccounts"
      @toggleProfile="toggleProfile"
      @click="toggleProfile"
    />
    <ProfileMenuAccountList
      class="absolute bottom-[175px] left-[380px] w-[280px] max-h-[500px]"
      :class="{ '!left-[570px]': expanded }"
      :show-accounts="showProfile && showAccounts"
      @setShowAccounts="setShowAccounts"
    />
  </div>
</template>
<script setup>
import ProfileMenuAccountList from '@/components/navbar/ProfileMenuAccountList'
import ProfileMenuBody from '@/components/navbar/ProfileMenuBody'
import ProfilePhoto from '@/components/shared/ProfilePhoto'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  showProfile: {
    type: Boolean,
    default: false
  },
  expanded: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['toggleProfile', 'logout'])

const el = ref(null)
const showAccounts = ref(false)
const currentUser = computed(() => store.state.workspace.currentUser)

function handleLogout() {
  emit('logout')
}
function toggleProfile() {
  emit('toggleProfile')
}
function setShowAccounts(show) {
  showAccounts.value = show
}
</script>
